import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { Layout, Songs } from '../components'
import { TEXTS } from '../constants'

export default () => {
    const { songs } = useStaticQuery(graphql`
      {
        songs: allContentfulSong(filter: {visible: {eq: true}}, sort: {fields: [year, title]}) {
          nodes {
            id
            title
            year
            singers
            musicAuthor
            lyricsAuthor
            fileOgg {
              localFile {
                publicURL
              }
            }
            fileMp3 {
              localFile {
                publicURL
              }
            }
            isLyricsAndMusicMerged
            isShowSingersLabel
          }
        }
      }`)
    return (
        <Layout header={TEXTS.songs} pageTitle={TEXTS.songs}>
            <Songs songs={songs.nodes}/>
        </Layout>
    )
}
